var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "approveShow",
      attrs: { fullscreen: _vm.isFullscreen, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "margin-right": "30px",
            display: "flex",
            "justify-content": "space-between",
            cursor: "pointer",
          },
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.title))]),
          _c("Icon", {
            attrs: { type: "md-expand", size: "24", color: "#ccc" },
            on: { click: _vm.changeFull },
          }),
        ],
        1
      ),
      _c(
        "Row",
        { staticStyle: { "flex-wrap": "nowrap" }, attrs: { gutter: 4 } },
        [
          _c(
            "Col",
            { staticStyle: { display: "flex" }, attrs: { span: "24" } },
            [
              _vm.orderId && _vm.orderId.trim().length > 0
                ? _c(
                    "Card",
                    {
                      staticStyle: {
                        height: "100%",
                        width: "60%",
                        "overflow-y": "auto",
                        display: "flex",
                      },
                    },
                    [
                      _c(
                        "Tabs",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { size: "small", value: _vm.tabsClickIndex },
                          on: { "on-click": _vm.tabsClick },
                        },
                        [
                          !_vm.loading
                            ? _c(
                                "TabPane",
                                {
                                  staticStyle: { height: "100%" },
                                  attrs: { label: "委托协议", name: "0" },
                                },
                                [
                                  _vm.orderInfo &&
                                  _vm.orderInfo.orderPath &&
                                  _vm.orderInfo.orderPath.trim().length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "previewIframe",
                                          staticStyle: { height: "100%" },
                                        },
                                        [
                                          _c("iframe", {
                                            ref: "previewHtmlTemplatePS",
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                            },
                                            attrs: {
                                              src:
                                                _vm.pathLeft +
                                                _vm.orderInfo.orderPath,
                                              frameborder: "0",
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticStyle: { height: "100%" } },
                                        [
                                          _c("no-data", {
                                            staticStyle: {
                                              height: "100%",
                                              overflow: "hidden",
                                              display: "flex",
                                              "flex-direction": "row",
                                              "justify-content": "center",
                                            },
                                            attrs: { width: "150" },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.loading
                            ? _c(
                                "TabPane",
                                {
                                  staticStyle: { height: "100%" },
                                  attrs: { label: "营业执照", name: "2" },
                                },
                                [
                                  _vm.attachmentUrl &&
                                  _vm.attachmentUrl.trim().length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "previewIframe",
                                          staticStyle: { height: "92.8%" },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              overflow: "hidden",
                                              width: "100%",
                                              height: "100%",
                                            },
                                            attrs: { src: _vm.attachmentUrl },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticStyle: { height: "100%" } },
                                        [
                                          _c("no-data", {
                                            staticStyle: {
                                              height: "100%",
                                              overflow: "hidden",
                                              display: "flex",
                                              "flex-direction": "row",
                                              "justify-content": "center",
                                            },
                                            attrs: { width: "150" },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.orderId && _vm.orderId.trim().length > 0
                ? _c(
                    "Card",
                    {
                      staticClass: "cardApproveClass",
                      staticStyle: {
                        height: "100%",
                        width: "40%",
                        "overflow-y": "auto",
                        display: "flex",
                      },
                    },
                    [
                      _c(
                        "Form",
                        {
                          ref: "reviewContentForm",
                          staticClass: "form",
                          staticStyle: { display: "flex" },
                          attrs: {
                            model: _vm.reviewContentForm,
                            rules: _vm.reviewContentFormRule,
                          },
                        },
                        [
                          _c("div", { staticStyle: { width: "100%" } }, [
                            (_vm.reviewContentForm.auditLevel == 1 &&
                              !_vm.showStatu) ||
                            (_vm.reviewContentForm.auditLevel >= 2 &&
                              _vm.showStatu)
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "12px" } },
                                      [
                                        _vm._v(
                                          "1.用人单位提供的资料（盖鲜章）："
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "18px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "（1）用人单位基本情况信息表：",
                                                  prop: "isInfoTable",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isInfoTable,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isInfoTable",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isInfoTable",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "（2）近期职业病危害因素检测报告或评价报告：",
                                                  prop: "isAssessmentReport",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isAssessmentReport,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isAssessmentReport",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isAssessmentReport",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "（3）营业执照（三证合一）：",
                                                  prop: "isBusinessLicense",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isBusinessLicense,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isBusinessLicense",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isBusinessLicense",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "（4）合同（委托书）：",
                                                  prop: "isEntrustReport",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isEntrustReport,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isEntrustReport",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isEntrustReport",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "（5）预体检人员名单信息（姓名、性别、身份证号、手机号、工龄、岗位、职业病危害因素、接害工龄等）：",
                                                  prop: "isPersonInfo",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isPersonInfo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isPersonInfo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isPersonInfo",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "2.检查类别和项目是否与备案一致：",
                                                  prop: "isKeepRecord",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isKeepRecord,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isKeepRecord",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isKeepRecord",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "3.仪器设备是否满足合同（协议）所订职业健康检查需求：",
                                                  prop: "isEquipmentTrue",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isEquipmentTrue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isEquipmentTrue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isEquipmentTrue",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "4.委托单位要求是否符合国家有关法律、政策和标准规范的要求：",
                                                  prop: "isStandard",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isStandard,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isStandard",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isStandard",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "5.是否与委托单位进行了真实而有效的沟通，了解委托单位的真实需求：",
                                                  prop: "isUnderstandNeeds",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isUnderstandNeeds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isUnderstandNeeds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isUnderstandNeeds",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "6.是否涉及项目的分包：",
                                                  prop: "isSubcontract",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isSubcontract,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isSubcontract",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isSubcontract",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "7.委托方对体检时间、期限有无特别要求：",
                                                  prop: "isSpecialRequirements",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isSpecialRequirements,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isSpecialRequirements",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isSpecialRequirements",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "8.是否告知委托方体检方案及体检注意事项：",
                                                  prop: "isInform",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isInform,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isInform",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isInform",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "9.报告领取方式，是否委托方自取：",
                                                  prop: "isTakeFromOneself",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isTakeFromOneself,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isTakeFromOneself",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isTakeFromOneself",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("否")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("是")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            (_vm.reviewContentForm.auditLevel == 2 &&
                              !_vm.showStatu) ||
                            (_vm.reviewContentForm.auditLevel >= 3 &&
                              _vm.showStatu)
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "6px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label:
                                                    "可以为委托单位提供职业健康检查服务：",
                                                  prop: "isCanService",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isCanService,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isCanService",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isCanService",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("不可以")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("可以")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.reviewContentForm.isCanService == "1"
                                      ? _c(
                                          "Row",
                                          {
                                            staticStyle: {
                                              "margin-top": "6px",
                                            },
                                          },
                                          [
                                            _c(
                                              "Col",
                                              { attrs: { span: "24" } },
                                              [
                                                _c(
                                                  "FormItem",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "不能为委托单位提供职业健康检查服务，原因：",
                                                      prop: "reason",
                                                    },
                                                  },
                                                  [
                                                    _c("Input", {
                                                      staticStyle: {
                                                        "margin-top": "10px",
                                                      },
                                                      attrs: {
                                                        type: "textarea",
                                                        maxlength: 50,
                                                        placeholder:
                                                          "请输入原因",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.reviewContentForm
                                                            .reason,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.reviewContentForm,
                                                            "reason",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "reviewContentForm.reason",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            (_vm.reviewContentForm.auditLevel == 3 &&
                              !_vm.showStatu) ||
                            (_vm.reviewContentForm.auditLevel >= 4 &&
                              _vm.showStatu)
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c(
                                      "Row",
                                      { staticStyle: { "margin-top": "6px" } },
                                      [
                                        _c(
                                          "Col",
                                          { attrs: { span: "24" } },
                                          [
                                            _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label: "是否同意批准：",
                                                  prop: "isAgree",
                                                },
                                              },
                                              [
                                                _c(
                                                  "RadioGroup",
                                                  {
                                                    model: {
                                                      value:
                                                        _vm.reviewContentForm
                                                          .isAgree,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.reviewContentForm,
                                                          "isAgree",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reviewContentForm.isAgree",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 1 } },
                                                      [_vm._v("不同意")]
                                                    ),
                                                    _c(
                                                      "Radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("同意")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.reviewContentForm.isAgree == "1"
                                      ? _c(
                                          "Row",
                                          {
                                            staticStyle: {
                                              "margin-top": "6px",
                                            },
                                          },
                                          [
                                            _c(
                                              "Col",
                                              { attrs: { span: "24" } },
                                              [
                                                _c(
                                                  "FormItem",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "不同意批准，原因：",
                                                      prop: "reasonNoAgree",
                                                    },
                                                  },
                                                  [
                                                    _c("Input", {
                                                      staticStyle: {
                                                        "margin-top": "10px",
                                                      },
                                                      attrs: {
                                                        type: "textarea",
                                                        maxlength: 50,
                                                        placeholder:
                                                          "请输入原因",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.reviewContentForm
                                                            .reasonNoAgree,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.reviewContentForm,
                                                            "reasonNoAgree",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "reviewContentForm.reasonNoAgree",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            { attrs: { type: "text" }, on: { click: _vm.closeLoading } },
            [_vm._v("取消")]
          ),
          _vm.showStatu && _vm.orderInfo && _vm.orderInfo.id
            ? _c(
                "Button",
                {
                  attrs: { type: "info", icon: "md-eye" },
                  on: {
                    click: function ($event) {
                      _vm.showOrderContract = true
                    },
                  },
                },
                [_vm._v("附件查看")]
              )
            : _vm._e(),
          _c(
            "Button",
            { attrs: { type: "primary" }, on: { click: _vm.handelSubmitNow } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
      _c("contract-preview", {
        attrs: { groupOrderInfo: _vm.orderInfo, url: _vm.orderInfo.orderPath },
        model: {
          value: _vm.showOrderContract,
          callback: function ($$v) {
            _vm.showOrderContract = $$v
          },
          expression: "showOrderContract",
        },
      }),
      _c("review-content", {
        attrs: {
          orderId: _vm.orderInfo.id,
          orderInfo: _vm.orderInfo,
          flowData: _vm.flowData,
        },
        on: {
          closeLoading: _vm.closeApproveShow,
          submitApproveShowPage: _vm.submitApproveShowPage,
        },
        model: {
          value: _vm.reviewContentShow,
          callback: function ($$v) {
            _vm.reviewContentShow = $$v
          },
          expression: "reviewContentShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }